// https://github.com/vercel/next.js/blob/canary/examples/with-sentry/pages/_error.js
import NextErrorComponent, { ErrorProps } from 'next/error'
import { NextPageContext } from 'next'
import * as Sentry from '@sentry/nextjs'

interface ExtendedErrorProps extends ErrorProps {
  hasGetInitialPropsRun?: boolean
}

const MyError = ({
  statusCode,
  hasGetInitialPropsRun,
  err
}: ExtendedErrorProps & { err: NextPageContext['err'] }): JSX.Element => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err)
  }

  return <NextErrorComponent statusCode={statusCode} />
}

MyError.getInitialProps = async (
  context: NextPageContext
): Promise<ExtendedErrorProps> => {
  const errorInitialProps = (await NextErrorComponent.getInitialProps(
    context
  )) as ExtendedErrorProps

  errorInitialProps.hasGetInitialPropsRun = true

  const { err, asPath } = context
  if (err) {
    Sentry.captureException(err)

    await Sentry.flush(2000)

    return errorInitialProps
  }

  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  )
  await Sentry.flush(2000)

  return errorInitialProps
}

export default MyError
